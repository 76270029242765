const Theme = {
    token: {
        colorPrimary: '#977b4c',
        colorLink: '#977b4c',
        colorDark: '#0a2133',
        borderRadius: '0px',
        boxShadow: 'none',
        fontFamily: '\'Hanken Grotesk\', system-ui, BlinkMacSystemFont, \'Segoe UI\', Roboto, \'Helvetica Neue\', Arial, sans-serif;',
        fontSize: 18,
        controlHeight: 38,
        paddingContentHorizontal: 24
    },
}

export default Theme