import React from 'react';
import App from './app';
import {ConfigProvider} from 'antd';
import theme from "./styles/theme";
import "./styles/styles.scss";
import {createCache, StyleProvider} from "@ant-design/cssinjs";

export default function WrapWithProvider({element}) {
    const cache = createCache();

    return (
        <ConfigProvider
            theme={theme}>
            <StyleProvider cache={cache} hashPriority="high">
                <App>
                    {element}
                </App>
            </StyleProvider>
        </ConfigProvider>
    );
}